.deal-card--image-side--top ion-img::part(image) {
  border-radius: 0 var(--border-radius--small) 0px 0px;
}

.deal-card--image-side--left ion-img::part(image) {
  border-radius: var(--border-radius--small);
}

.deal-card--associated {
  opacity: 0.4;
  font-style: italic;
  color: var(--ion-color-danger-shade);
}

.deal-card--referrer {
  opacity: 0.4;
  font-style: italic;
  color: var(--ion-color-warning-shade);
}
